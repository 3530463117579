import { useState } from "react";
import "./NewFooter.scss";
import { Image, Row, Col, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookSquare, faYoutube, faInstagram, faTwitter, faLinkedin, faGithub } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope, faSearchLocation, faPhoneAlt, faPhoneSquareAlt, faMobile, faMobileAlt } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
const logo = "./asessts/images/logo/logo.png";
const instagram = "./asessts/images/instagram.png";

function NewMobileFooter() {
  const master = "../../asessts/images/master.png";
  const visa = "../../asessts/images/visa.png";
  const paypal = "../../asessts/images/paypal.png";
  const stripeImage = "../../asessts/images/stripe.png";

  const [open, setOpen] = useState();
  const [open1, setOpen1] = useState();
  const [open2, setOpen2] = useState();
  const [open3, setOpen3] = useState();
  const [open4, setOpen4] = useState();
  const [open5, setOpen5] = useState();
  const [open6, setOpen6] = useState();

  return (
    <div id="mobileFooter">
      <a target="_blank" href="https://wa.me/447354473871" id="whatsapp">
        <img src="../../asessts/images/whatsapp.png" />
      </a>
      <div className="mobileFooter-details">
        <div className="d-flex justify-content-center">
          {/* <img src="../../../asessts/images/logo/logo.png" /> */}
        </div>
        <div className="accordion">
          <div className="acc-1">
            <div className="d-flex justify-content-between" onClick={() => setOpen(!open)}>
              <h5 style={{ color: "black" }}>Address</h5>
              {open ? <h5>-</h5> : <h5>+</h5>}
            </div>
            {open ? (
              <div>
                <div>
                  <p className="text-muted" style={{ fontSize: "0.9rem", wordSpacing: "4px", lineHeight: "23px" }}>
                    <FontAwesomeIcon icon={faSearchLocation} />
                    &nbsp; Office # 1 G-Floor Building #43, Street # 9 Shama Road, Ichhra Lahore, Punjab 54600
                  </p>
                  <p className="text-muted" style={{ fontSize: "0.9rem", wordSpacing: "4px", lineHeight: "23px" }}>
                    <FontAwesomeIcon icon={faSearchLocation} />
                    &nbsp;   93 Bennetts Castle Lane, Dagenham, England, RM8 3YB
                  </p>
                  <p style={{ fontSize: "0.9rem", wordSpacing: "4px", lineHeight: "23px" }}>
                    <FontAwesomeIcon icon={faEnvelope} />{" "}
                    <a className="text-decoration-none text-muted" href="mailto:info@wellcreator.com">
                      info@wellcreator.com
                    </a>
                  </p>

                  <div className="d-flex">
                    <p className="pe-3" style={{ fontSize: "0.9rem", wordSpacing: "4px", lineHeight: "23px" }}>
                      <FontAwesomeIcon icon={faPhoneAlt} />{" "}
                      <a className="text-decoration-none text-muted" href="tel:042 37583100">
                        042-37583100
                      </a>
                    </p>
                    <p style={{ fontSize: "0.9rem", wordSpacing: "4px", lineHeight: "23px" }}>
                      <FontAwesomeIcon icon={faMobileAlt} />{" "}
                      <a className="text-decoration-none text-muted" href="tel: 0323 4022301">
                        {" "}
                        0323-4022301
                      </a>
                    </p>
                  </div>
                  {/* <h5 className="text-color mt-5 text-start">Follow us on</h5> */}
                  <p className="pe-3" style={{ fontSize: "0.9rem", wordSpacing: "4px", lineHeight: "23px" }}>
                    <FontAwesomeIcon icon={faPhoneAlt} />{" "}
                    <a className="text-decoration-none text-muted" href="tel:+447354473871">
                      +44 7354 473871
                    </a>
                  </p>
                </div>
                <div className="mt-2">
                  <p className="text-start ps-2 text-muted" style={{ fontSize: "0.9rem", wordSpacing: "4px", lineHeight: "1.7" }}>
                    "Design, Develop & Deploy."
                    <br />
                    <span>
                      <b>Wellcreator</b>
                    </span>
                  </p>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="acc-1">
            <div className="d-flex justify-content-between" onClick={() => setOpen1(!open1)}>
              <h5 style={{ color: "black" }}>Become a Creator</h5>
              {open1 ? <h5>-</h5> : <h5>+</h5>}
            </div>
            {open1 ? (
              <div>
                <div className="d-flex flex-column mt-3">
                  <Link className="text-decoration-none text-muted" to="/blog" style={{ fontSize: "0.9rem", lineHeight: "30px", wordSpacing: "4px", fontWeight: "300" }}>
                    Blog
                  </Link>
                  <Link className="text-decoration-none text-muted" to="/portfolio" style={{ fontSize: "0.9rem", lineHeight: "30px", wordSpacing: "4px", fontWeight: "300" }}>
                    Portfolio
                  </Link>
                  <Link className="text-decoration-none text-muted" to="//Referral-Program"  style={{ fontSize: "0.9rem", lineHeight: "30px", wordSpacing: "4px", fontWeight: "300" }}>Referral Program</Link>
                  <Link className="text-decoration-none text-muted" to="" style={{ fontSize: "0.9rem", lineHeight: "30px", wordSpacing: "4px", fontWeight: "300" }}></Link>
                  <Link className="text-decoration-none text-muted" to="/hrm" style={{ fontSize: "0.9rem", lineHeight: "30px", wordSpacing: "4px", fontWeight: "300" }}>
                    Products
                  </Link>
                  <Link className="text-decoration-none text-muted" to="/" style={{ fontSize: "0.9rem", lineHeight: "30px", wordSpacing: "4px", fontWeight: "300" }}>
                    Services
                  </Link>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="acc-1">
            <div className="d-flex justify-content-between" onClick={() => setOpen2(!open2)}>
              <h5 style={{ color: "black" }} >Services</h5>
              {open2 ? <h5>-</h5> : <h5>+</h5>}
            </div>
            {open2 ? (
              <div>
                <div className="d-flex flex-column">
                  <Link
                    to="/website-development"
                    className="py-1 text-decoration-none text-muted" style={{ fontSize: '0.9rem', lineHeight: '23px', wordSpacing: '4px', fontWeight: '300' }}
                  >
                    Website Development
                  </Link>{" "}
                  <Link
                    to="/custom-software-development-services"
                    className="py-1 text-decoration-none text-muted" style={{ fontSize: '0.9rem', lineHeight: '23px', wordSpacing: '4px', fontWeight: '300' }}
                  >
                    Software Development
                  </Link>
                  <Link
                    to="/unity-game-development-company"
                    className="py-1 text-decoration-none text-muted" style={{ fontSize: '0.9rem', lineHeight: '23px', wordSpacing: '4px', fontWeight: '300' }}
                  >
                    Unity Development
                  </Link>
                  <Link
                    to="/mobile-development"
                    className="py-1 text-decoration-none text-muted" style={{ fontSize: '0.9rem', lineHeight: '23px', wordSpacing: '4px', fontWeight: '300' }}
                  >
                    Mobile App Development
                  </Link>

                  <Link
                    to="/affordable-seo-services-uk"
                    className="py-1 text-decoration-none text-muted" style={{ fontSize: '0.9rem', lineHeight: '23px', wordSpacing: '4px', fontWeight: '300' }}
                  >
                    Search Engine Optimization
                  </Link>
                   <Link
                    to="/shopify-development-services"
                    className="py-1 text-decoration-none text-muted" style={{ fontSize: '0.9rem', lineHeight: '23px', wordSpacing: '4px', fontWeight: '300' }}
                  >
                    Shopify Devleopment Services
                  </Link>
                   {/* <Link
                    to="/DigitalMarketing"
                    className="py-1 text-decoration-none text-muted" style={{ fontSize: '0.9rem', lineHeight: '23px', wordSpacing: '4px', fontWeight: '300' }}
                  >
                    Digital Marketing
                  </Link> */}
                  <Link
                    to="/wordpress-development-services"
                    className="py-1 text-decoration-none text-muted" style={{ fontSize: '0.9rem', lineHeight: '23px', wordSpacing: '4px', fontWeight: '300' }}
                  >
                    Wordpress Development Services
                  </Link>
                  <Link
                    to="/.Net-development-services"
                    className="py-1 text-decoration-none text-muted" style={{ fontSize: '0.9rem', lineHeight: '23px', wordSpacing: '4px', fontWeight: '300' }}
                  >
                    .Net Devlopment
                  </Link> 

                </div>
              </div>) : ('')}
          </div>
          <div className="acc-1">
            <div className="d-flex justify-content-between" >
              <h5 style={{ color: "black" }}><Link to="/about-us" style={{textDecoration:"none" , color :"black"}}>About Us</Link></h5>
          </div>
          </div>
          <div className="acc-1">
            <div className="d-flex justify-content-between" onClick={() => setOpen4(!open4)}>
              <h5 style={{ color: "black" }}>Others</h5>
              {open4 ? <h5>-</h5> : <h5>+</h5>}
            </div>
            {open4 ? (
              <div className="d-flex flex-column">
                <Link
                  className="text-decoration-none text-muted"
                  to="/privacy-policy"
                  style={{ fontSize: '0.9rem', lineHeight: '30px', wordSpacing: '4px', fontWeight: '300' }}
                >
                  Privacy & Policy
                </Link>
                <Link
                  className="text-decoration-none text-muted"
                  to="/contact-us"
                  style={{ fontSize: '0.9rem', lineHeight: '30px', wordSpacing: '4px', fontWeight: '300' }}
                >
                  Contact us
                </Link>

                <Link
                  className="text-decoration-none text-muted"
                  to="/faqs"
                  style={{ fontSize: '0.9rem', lineHeight: '30px', wordSpacing: '4px', fontWeight: '300' }}
                >
                  FAQs
                </Link>
                <Link
                  className="text-decoration-none text-muted"
                  to="/"
                  style={{ fontSize: '0.9rem', lineHeight: '30px', wordSpacing: '4px', fontWeight: '300' }}
                >
                  Domain Registeration
                </Link>
                <Link
                  className="text-decoration-none text-muted"
                  to="/"
                  style={{ fontSize: '0.9rem', lineHeight: '30px', wordSpacing: '4px', fontWeight: '300' }}
                >
                  Wordpress Hosting
                </Link>
                <Link
                  className="text-decoration-none text-muted"
                  to="/qutation"
                  style={{ fontSize: '0.9rem', lineHeight: '30px', wordSpacing: '4px', fontWeight: '300' }}
                >
                  Qutation
                </Link>
              </div>) : ('')}
          </div>
          <div className="acc-1">
            <div className="d-flex justify-content-between" onClick={() => setOpen5(!open5)}>
              <h5 style={{ color: "black" }}>Customer Services</h5>
              {open5 ? <h5>-</h5> : <h5>+</h5>}
            </div>
            {open5 ? (
              <div className="d-flex flex-column">
                <Link style={{ fontSize: '0.9rem', lineHeight: '23px', wordSpacing: '4px', fontWeight: '300' }} to="/contact-us" className="py-1 text-decoration-none text-muted" >
                  Contact Us
                </Link>

                <Link
                  className="text-decoration-none text-muted"
                  to="/qutation"
                  style={{ fontSize: '0.9rem', lineHeight: '30px', wordSpacing: '4px', fontWeight: '300' }}
                >
                  Qutation
                </Link>
              </div>) : ('')}
          </div>
          <div className="acc-1">
            <div className="d-flex justify-content-between" onClick={() => setOpen6(!open6)}>
              <h5 style={{ color: "black" }}>Newsletter</h5>
              {open6 ? <h5>-</h5> : <h5>+</h5>}
            </div>
            {open6 ? (
              <div className="d-flex flex-column">
                <Form className="mt-3">
                  <p>Level up your game and stay tuned for promotions.</p>
                  <Form.Group controlId="formBasicEmail">
                    <Form.Control className="bg-light p-2 px-3 text-center shadow-sm border-light" type="email" placeholder="Enter email" />
                  </Form.Group>
                  <button className="btn mt-2 sub-btn text-white">SUBSCRIBE</button>
                </Form>
              </div>
            ) : (
              ""
            )}
          </div>
          <Row className="my-2 mt-3 d-flex justify-content-center">

            <Col xs={2} className="text-center" >
              <a target="_blank" href="https://www.facebook.com/wellcreator">

                <FontAwesomeIcon icon={faFacebookSquare} style={{ fontSize: '2rem' }} />
              </a>
            </Col>
            <Col xs={2} className="text-center" >
              <a target="_blank" href="https://github.com/wellcreator">

                <FontAwesomeIcon icon={faGithub} style={{ color: "#282828", fontSize: '2rem' }} />
              </a>
            </Col>
            <Col xs={2} className="text-center">
              <a target="_blank" href="https://twitter.com/wellcreator">

                <FontAwesomeIcon
                  style={{ color: "00acee", fontSize: '2rem' }}
                  icon={faTwitter}
                />
              </a>
            </Col>
            <Col xs={2} className="text-center">
              <a target="_blank" href="https://www.instagram.com/wellcreator/">

                <FontAwesomeIcon
                  style={{ color: "#E1306C", fontSize: '2rem' }}
                  icon={faInstagram}
                />
              </a>
            </Col>
            <Col xs={2} className="text-center">
              <a target="_blank" href="https://www.youtube.com/channel/UCMYAfqVrzCDHOvlHJ1paIsw">

                <FontAwesomeIcon
                  style={{ color: "#FF0000", fontSize: '2rem' }}
                  icon={faYoutube}
                />
              </a>
            </Col>
            <Col xs={2} className="text-center">
              <a target="_blank" href="https://www.linkedin.com/company/wellcreator/">
                {" "}
                <FontAwesomeIcon
                  style={{ color: "#1666C2", fontSize: '2rem' }}
                  icon={faLinkedin}
                />
              </a>
            </Col>
          </Row>
          <div className="border d-flex justify-content-center py-2">
            <Image className="payment mx-3" style={{ width: 'auto', height: '30px', objectFit: 'contain' }} src={visa} />
            <Image className="payment mx-3" style={{ width: 'auto', height: '30px', objectFit: 'contain' }} src={master} />
            <Image className="payment mx-3" style={{ width: 'auto', height: '30px', objectFit: 'contain' }} src={paypal} />
            <Image className="payment mx-3" style={{ width: 'auto', height: '30px', objectFit: 'contain' }} src={stripeImage} />
          </div>
          <div className="footer-foot m-0 text-center d-flex justify-content-between mt-3 mb-lg-0 flex-md-row flex-column">
            <div className="d-flex flex-wrap justify-content-center">
              <Link style={{ color: ' #000000', fontSize: '13px' }} className="  px-1" to="/sitemap">Sitemap|</Link>{" "}

              <Link style={{ color: ' #000000', fontSize: '13px' }} className="  " to="/career">Career|</Link>{" "}

              <Link style={{ color: ' #000000', fontSize: '13px' }} className=" px-1" to="/terms-conditions">Terms & Condition |&nbsp;</Link>{" "}

              <Link style={{ color: ' #000000', fontSize: '13px' }} className=" " to="/privacy-policy">Privacy Policy</Link>{" "}

              <Link style={{ color: ' #000000', fontSize: '13px' }} className=" " to="/RefundPolicy">| Re-fund Policy</Link>{" "}

            </div>
            <p className="foot-laste align-self-center mb-0" style={{ fontSize: '13px' }}>
              © Wellcreator 2012 - 2024. All Rights Reserved.
            </p>

          </div>
        </div>
      </div>
    </div>
  );
}

export default NewMobileFooter;
