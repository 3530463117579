import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import "./Footer.scss";
import { Image, Row, Col, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookSquare, faInstagram, faTwitter, faLinkedin, faGithub } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope, faSearchLocation, faPhoneAlt, faPhoneSquareAlt, faMobile, faMobileAlt } from "@fortawesome/free-solid-svg-icons";
import { BsFacebook, BsInstagram, BsGithub, BsTwitter, BsLinkedin, BsYoutube } from "react-icons/bs";

const logo = "./asessts/images/logo/logo.png";
const instagram = "./asessts/images/instagram.png";
function Footer() {
  const master = "../../asessts/images/master.png";
  const visa = "../../asessts/images/visa.png";
  const paypal = "../../asessts/images/paypal.png";
  const stripeImage = "../../asessts/images/stripe.png";

  return (
    <footer className="footer-web footer-bg border-top">
      <a target="_blank" href="https://wa.me/447354473871" id="whatsapp">
        <img src="../../asessts/images/whatsapp.png" />
      </a>
      <div className="footer-content footer-foot mt-lg-2 pb-3">
        <div className="p-md-4 p-3 px-lg-5 ">
          <Row>
            <Col xs={12} md={3}>
              <div className="mx-2">
                <h3 className="custom-heading mb-4">Address</h3>
                <div className="d-flex flex-column">
                  <div className="">
                    <p className=" pe-3 mt-0">
                      <FontAwesomeIcon className="text-color" icon={faPhoneAlt} />{" "}
                      <a className="text-decoration-none " href="tel:042 37583100">
                        042-37583100  (Landline)
                      </a>

                    </p>
                    <p className="">
                      <FontAwesomeIcon className="text-color" icon={faMobileAlt} />{" "}
                      <a className="text-decoration-none " href="https://wa.me/03234022301" target="_blank">
                        0323-4022301  (Pakistan)
                      </a>

                    </p>
                  </div>
                  <a className="text-decoration-none">
                    <FontAwesomeIcon className="text-color" icon={faSearchLocation} />
                    &nbsp; Office # 1 G-Floor Building #43, Street # 9 Shama Road, Ichhra Lahore, Punjab 54600
                  </a>

                  <div>

                    <p className="pe-3 mt-3" style={{ fontSize: "0.9rem", wordSpacing: "4px", lineHeight: "23px" }}>
                      <FontAwesomeIcon className="text-color" icon={faMobileAlt} />{" "}
                      <a className="text-decoration-none  " target="_blank" href="https://wa.me/447354473871">
                        +44 7354 473871 (UK)
                      </a>
                    </p>
                    <a className="text-decoration-none  my-3">
                      <FontAwesomeIcon className="text-color" icon={faSearchLocation} />
                      &nbsp; 93 Bennetts Castle Lane, Dagenham, England, RM8 3YB
                    </a>
                  </div>


                </div>
                <p className="mt-3" style={{ fontSize: "0.9rem", wordSpacing: "4px", lineHeight: "23px" }}>
                  <FontAwesomeIcon className="text-color" icon={faEnvelope} />{" "}
                  <a className="text-decoration-none " href="mailto:info@wellcreator.com">
                    info@wellcreator.com
                  </a>
                </p>
                {/* <h5 className="text-color mt-5 text-start">Follow us on</h5> */}
              </div>
            </Col>
            <Col xs={12} md={3}>
              <h3 className="custom-heading">Services</h3>
              <div className="d-flex flex-column mt-4">
                <Link to="/mobile-development" className="custom-link">
                  Mobile App Development
                </Link>
                 <Link to="/wordpress-development-services" className="custom-link">
                  WordPress Development Services
                </Link> 
                <Link to="/custom-software-development-services" className="custom-link">
                  Software Development
                </Link>
                <Link to="/website-development" className="custom-link">
                  Website Development{" "}
                </Link>{" "}
                <Link to="/unity-game-development-company" className="custom-link">
                  Unity Development
                </Link>
                <Link to="/affordable-seo-services-uk" className="custom-link">
                  SEO ( Search Engine Optimization )
                </Link>
                <Link to="/shopify-development-services" className="custom-link">
                  Shopify Devleopment Services
                </Link>
                {/* <Link to="/DigitalMarketing" className="custom-link">
                 Digital Marketing 
               </Link> */}
                <Link to="/.Net-development-services" className="custom-link">
                  .Net Devlopment
                </Link> 






              </div>
            </Col>
            <Col xs={12} md={3} className="mt-4" >
              {/* <h3 className="custom-heading">About</h3> */}
              <div className="mt-4 d-flex flex-column"

              >
                <Link to="/" className="custom-link">
                  Email Marketing Software
                </Link>

                {/* new saas page */}
                <Link to="/sass-webpage" className="custom-link">
                  SaaS Application Development
                </Link>
                <Link to="" className="custom-link">
                  Hire Software Developer
                </Link>

                <Link className="custom-link" to="/">
                  DevOps Services
                </Link>
                <Link className="custom-link" to="/">
                  AI Website Generator
                </Link>
                <Link className="custom-link" to="/">
                  Software QA Testing
                </Link>
                <Link to="/" className="custom-link">
                  Enterprise Software Development
                </Link>
                {/* <Link to="/" className="custom-link">
                  Wordpress Development
                </Link> */}
                <Link to="/" className="custom-link">
                  Marketing Analytis
                </Link>


              </div>
              {/* <h3 className="custom-heading text-overflow mt-4">Customer Services</h3> */}
              <div className="d-flex flex-column">

              </div>
            </Col>

            <Col xs={12} md={3} className="">
              <h3 className="custom-heading">Newsletter</h3>
              <p className="mt-4" style={{ fontSize: "1rem", lineHeight: "28px", wordSpacing: "4px" }}>
                Leval up your game and stay tuned for promotions.
              </p>
              <Form>
                <Form.Group controlId="formBasicEmail">
                  <Form.Control className="bg-light p-2 px-3 text-center shadow-sm border-light" type="email" placeholder="Enter email" />
                </Form.Group>
                <button className="btn mt-4 sub-btn text-white">SUBSCRIBE</button>
              </Form>
              <Row className="my-2 mt-3 d-flex justify-content-center">
                <Col xs={2} className=" text-center">
                  <a className="custom-social-link" target="_blank" href="https://www.facebook.com/wellcreator">
                    <BsFacebook />
                  </a>
                </Col>
                <Col xs={2} className="">
                  <a className=" custom-social-link" target="_blank" href="https://github.com/wellcreator">
                    <BsGithub />
                  </a>
                </Col>
                <Col xs={2} className="">
                  <a className=" custom-social-link" target="_blank" href="https://twitter.com/wellcreator">
                    <BsTwitter />
                  </a>
                </Col>
                <Col xs={2} className="">
                  <a className=" custom-social-link" target="blank" href="https://www.instagram.com/wellcreator/">
                    <BsInstagram />
                  </a>
                </Col>
                <Col xs={2} className="">
                  <a className=" custom-social-link" target="_blank" href="https://www.linkedin.com/company/wellcreator/">
                    <BsLinkedin />
                  </a>
                </Col>
                <Col xs={2} className="">
                  <a className=" custom-social-link" target="_blank" href="https://www.youtube.com/channel/UCMYAfqVrzCDHOvlHJ1paIsw">
                    <BsYoutube />
                  </a>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
        <div className="border d-flex justify-content-center py-2">
          <Image className="payment mx-3" style={{ width: "auto", height: "40px", objectFit: "contain" }} src={visa} />
          <Image className="payment mx-3" style={{ width: "auto", height: "40px", objectFit: "contain" }} src={master} />
          <Image className="payment mx-3" style={{ width: "auto", height: "40px", objectFit: "contain" }} src={paypal} />
          <Image className="payment mx-3" style={{ width: "auto", height: "40px", objectFit: "contain" }} src={stripeImage} />
        </div>
        <div className="footer-foot m-0 text-center d-flex justify-content-between mt-3 px-5 mb-lg-0 mb-5 flex-md-row flex-column">
          <p className="foot-first align-self-center mb-0">
            <Link className="" to="/sitemap">
              Sitemap{" "}
            </Link>{" "}
            |{" "}
            <Link className="" to="/career">
              {" "}
              Career
            </Link>{" "}
            |{" "}
            <Link className="" to="/terms-conditions">
              Terms & Condition{" "}
            </Link>
            |{" "}
            <Link className="" to="/privacy-policy">
              Privacy Policy
            </Link>{" "}
            |{" "}
            <Link className="" to="/RefundPolicy">
              {" "}
              Refund Policy
            </Link>{" "}
            |{" "}
          </p>
          <p className="foot-last s align-self-center mb-0">© Wellcreator 2012 - 2024. All Rights Reserved.</p>
        </div>
      </div >
    </footer >
  );
}

export default Footer;
